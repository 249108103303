<template>
  <div class="dev-header">
    <div class="dev-header-left">
      <p class="dev-header-title third-font" dcolor>My Space 1A</p>
    </div>
    <div class="dev-header-right" v-if="latest">
      <DownloadButton :download="latest" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    DownloadButton: () => import("./DownloadButton.vue"),
  },
  computed: {
    downloads: function() {
      return this.$store.state.downloads.items;
    },
    latest: function() {
      return this.$store.state.downloads.latest.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.dev-header {
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include Backdrop();
  @include dshadow();
  &-title {
    padding: $mpadding;
    font-size: 120%;
    font-weight: bold;
  }
  &-right {
    padding: $mpadding / 2;
  }
}
</style>
